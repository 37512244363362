export default defineNuxtRouteMiddleware(async () => {
  const { $api } = useNuxtApp()
  const localePath = useLocalePath()

  try {
    await $api.v3.userAutomaticProposals.read()
  } catch {
    return navigateTo(localePath({ name: 'search' }))
  }
})
